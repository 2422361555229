.client-testimonial-bg 
    z-index: 1
    // background: url("./../../")

.client-testimonial-container 
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 10

.client-testimonial-container .alice-carousel__dots
    margin: 0 !important
    position: absolute
    bottom: -2rem
    right: 40%
    // margin-bottom: -14rem !important

.client-testimonial-container .alice-carousel__prev-btn
    display: none !important 
    
.client-testimonial-container .alice-carousel__next-btn
    display: none !important 

.client-testimonial-container .alice-carousel__stage-item
    // bottom: -7rem !important