.navbar-bg
    background: linear-gradient(117deg, #12093D 43.83%, #6842E5 118.22%)
    
.nav-logo 
    width: 22.8rem
    height: 4.9rem

.nav-icon
  width: 5rem
  height: 3rem
  position: relative
  margin: auto
  -webkit-transform: rotate(0deg)
  -moz-transform: rotate(0deg)
  -o-transform: rotate(0deg)
  transform: rotate(0deg)
  -webkit-transition: .5s ease-in-out
  -moz-transition: .5s ease-in-out
  -o-transition: .5s ease-in-out
  transition: .5s ease-in-out
  cursor: pointer

.nav-icon span 
  display: block
  position: absolute
  height: .6rem
  width: 100%
  background: #FFFFFF
  border-radius: .9rem
  opacity: 1
  left: 0
  -webkit-transform: rotate(0deg)
  -moz-transform: rotate(0deg)
  -o-transform: rotate(0deg)
  transform: rotate(0deg)
  -webkit-transition: .25s ease-in-out
  -moz-transition: .25s ease-in-out
  -o-transition: .25s ease-in-out
  transition: .25s ease-in-out

.nav-icon span:nth-child(1) 
  top: 0

.nav-icon span:nth-child(2),.nav-icon span:nth-child(3) 
  top: 1.4rem

.nav-icon span:nth-child(4) 
  top: 2.8rem

.nav-icon.open span:nth-child(1) 
  top: 1.4rem
  width: 0%
  left: 50%

.nav-icon.open span:nth-child(2) 
  -webkit-transform: rotate(45deg)
  -moz-transform: rotate(45deg)
  -o-transform: rotate(45deg)
  transform: rotate(45deg)

.nav-icon.open span:nth-child(3) 
  -webkit-transform: rotate(-45deg)
  -moz-transform: rotate(-45deg)
  -o-transform: rotate(-45deg)
  transform: rotate(-45deg)

.nav-icon.open span:nth-child(4) 
  top: 1.8rem
  width: 0%
  left: 50%