@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap')
@import './variables'

@import "react-alice-carousel/lib/scss/alice-carousel.scss"


html
  // font-size => 10px
  // 10px /16px => 0.625 => 62.5%
  // Percentage of users browser font-size setting
  font-size: 62.5% !important
  // overflow-x: hidden
  scroll-behavior: smooth

body
  margin: 0
  padding: 0
  overflow-x: hidden
  background-color: var(--background-color)
  font-family: 'Poppins', sans-serif

pre 
  font-family: "Poppins", sans-serif !important

.text-Montserrat-sans-serif 
  font-family: 'Montserrat', sans-serif !important

.text-DM-Sans-sans-serif 
  font-family: 'DM Sans', sans-serif !important

input
  border: none
  outline: none

.screen a
  display: contents
  text-decoration: none

.container-lg
  width: 160rem
  margin: 0 auto

.container-center-horizontal
  display: flex
  flex-direction: row
  justify-content: center
  pointer-events: none
  width: 100%
  height: auto

.container-center-horizontal > *
  flex-shrink: 0
  pointer-events: auto

*
  margin: 0
  padding: 0
  box-sizing: border-box

.App
  width: 100vw
  width: 100%

/* width */
::-webkit-scrollbar
  width: 1rem
  height: auto

/* Track */
::-webkit-scrollbar-track
  border-radius: 1rem

/* Handle */
::-webkit-scrollbar-thumb
  background: #c0c0c0
  border-radius: 1rem

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: #c0c0c0

@-moz-document url-prefix()

  /* width */
  ::-webkit-scrollbar
    width: 1rem
    height: auto

  /* Track */
  ::-webkit-scrollbar-track
    border-radius: 1rem

  /* Handle */
  ::-webkit-scrollbar-thumb
    background: #c0c0c0
    border-radius: 1rem

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: #c0c0c0


// class for cursor events
.cursor-pointer
  cursor: pointer

.cursor-default
  cursor: default

iframe
  display: none !important

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button 
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  margin: 0 

.position-absolute-center 
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 10

@media (max-width: 1600px) 
  html
    // font-size => 8px
    // 8px /16px => 0.5 => 50%
    // Percentage of users browser font-size setting
    font-size: 50% !important
    // overflow-x: hidden

@media (max-width: 1319px) 
  html
    // font-size => 9px
    // 8px /16px => 0.5 => 50%
    // Percentage of users browser font-size setting
    // font-size: 50% !important
    font-size: 50% !important
    // overflow-x: hidden
  .container-lg
    width: 120rem

@media (max-width: 1199px) 
  html
    // font-size => 9px
    // 6px /16px => 0.375 => 37.5%
    // Percentage of users browser font-size setting
    // font-size: 37.5% !important
    // font-size: 37.5% !important
    // overflow-x: hidden

@media (max-width: 992px) 
  html
    // font-size => 9px
    // 8px /16px => 0.5625 => 56.25%
    // Percentage of users browser font-size setting
    // font-size: 37.5% !important
    font-size: 56.25% !important
    // overflow-x: hidden
  .container-lg
    width: 86rem

@media (max-width: 768px) 
  html
    // font-size => 9px
    // 8px /16px => 0.5 => 50%
    // Percentage of users browser font-size setting
    // font-size: 50% !important
    font-size: 50% !important
    // overflow-x: hidden
  .container-lg
    width: 74rem


@media (max-width: 620px)
   .container-lg
    width: 71rem

@media (max-width: 576px) 
  html
    // font-size => 6px
    // 6px /16px => 0.375 => 37.5%
    // Percentage of users browser font-size setting
    font-size: 37.5% !important
    // overflow-x: hidden

  .container-lg
    width: 70rem

  /* width */
  ::-webkit-scrollbar
    width: .4rem
    height: auto

  @-moz-document url-prefix()
    /* width */
    ::-webkit-scrollbar
      width: .4rem
      height: auto  



@media ( max-width: 450px )
  .container-lg
    width: 62rem

@media (max-width: 420px) 
  html
    // font-size => 6px
    // 6px /16px => 0.375 => 37.5%
    // Percentage of users browser font-size setting
    font-size: 37.5% !important
    // overflow-x: hidden

  .container-lg
    width: 56rem