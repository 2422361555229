.about-us
    // anchors scrolling too far correction
    scroll-margin-top: 12rem
    scroll-snap-margin-top: 12rem

.about-us-bg
    background-image: url("../../../assets/img/about-us-sec-bg.jpg")
    background-size: contain
    background-repeat: no-repeat
    background-position: right

@media (max-width: 768px) 
    .about-us-bg
        background-image: none
